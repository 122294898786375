import * as React from "react";
import { useState } from "react";
import { ChangePasswordScreen } from "../components/onboarding/ChangePasswordScreen";
import { resetPassword } from "../infrastructure/api/auth";
import { Navigate, useNavigate } from "react-router-dom";
import useToken from "../hooks/useToken";

export default function ChangePassword() {
  const [error, setError] = useState("");
  const { token } = useToken();
  const navigate = useNavigate();

  const handleSetNewPassword = async (
    password: string,
    passwordConfirm: string,
    code: string
  ): Promise<void> => {
    if (password !== passwordConfirm) {
      setError("Passwörter stimmen nicht überein.");
      return;
    }
    try {
      await resetPassword(password, passwordConfirm, code);
      navigate("/login", { replace: true });
    } catch (e) {
      setError("Etwas ist schiefgelaufen.");
    }
  };

  if (token) {
    return <Navigate to={"/"} replace={true} />;
  }

  return (
    <ChangePasswordScreen
      error={error}
      onSetPasswordPress={(password, passwordConfirm, code) =>
        handleSetNewPassword(password, passwordConfirm, code)
      }
    />
  );
}
