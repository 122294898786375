import React from 'react';
import { Grid, Typography } from '@mui/material';
import {
    IResponse,
} from "../../infrastructure/api/leads";


export const RenderLeadResults = (funnelResults: IResponse | undefined) => {
    const results = [];
    if (funnelResults) {
        for (const [key, value] of Object.entries(funnelResults)) {
        results.push(
            <Grid item key={key}>
                <Typography fontWeight={700}>{value.title}</Typography>
                <Typography>{value.value}</Typography>
            </Grid>
        );
        }
    }
    return results;
}