import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    ICampaign,
    ICompany,
    IResponse,
} from "../../infrastructure/api/leads";
import { RenderLeadResults } from './RenderFunnelResults';
import { Grid, Typography } from '@mui/material';

interface IInformationAccordionProps {
    readonly funnelResults: IResponse | undefined
    readonly companyDetails: ICompany | undefined;
    readonly campaignDetails: ICampaign | undefined;
}

export default function InformationAccordion(props: IInformationAccordionProps) {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
            <Typography fontWeight={700}>{"Unternehmen"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Grid>
                <Typography fontWeight={700}>{"Kurz Beschreibung:"}</Typography>
                <Typography>{props.companyDetails?.data.attributes.description}</Typography>
            </Grid>
            <Grid pt={2}>
                <Typography fontWeight={700}>{"Hauptstandort:"}</Typography>
                <Typography>{props.companyDetails?.data.attributes.headquarter}</Typography>
            </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
            <Typography fontWeight={700}>{"Stelle"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Grid>
                <Typography fontWeight={700}>{"Aufgaben:"}</Typography>
                <Typography>{props.campaignDetails?.data.attributes.tasks}</Typography>
            </Grid>
            <Grid pt={2}>
                <Typography fontWeight={700}>{"Einsatzort:"}</Typography>
                <Typography>{props.campaignDetails?.data.attributes.place}</Typography>
            </Grid>
            <Grid pt={2}>
                <Typography fontWeight={700}>{"Sprachketnisse:"}</Typography>
                <Typography>{props.campaignDetails?.data.attributes.language}</Typography>
            </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
            <Typography fontWeight={700}>{"Funnel"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            {RenderLeadResults(props.funnelResults)}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
