import { AxiosRequestHeaders } from "axios";

export const getAuthHeader = (): AxiosRequestHeaders => {
  const tokenString = localStorage.getItem("token");
  if (tokenString) {
    const userToken = JSON.parse(tokenString);
    return { Authorization: `Bearer ${userToken.token}` };
  }
  return {};
};
