export const defaultQualification =
  "## 🔄 Wechselmotivation\n" +
  "\n" +
  "**Wieso hast du dich entschieden dich bei uns zu bewerben?**\n" +
  "\n" +
  "- \n" +
  "\n" +
  "**Was erhoffst du dir von der neuen Stelle?**\n" +
  "\n" +
  "- \n" +
  "\n" +
  "## 🧑‍🎓Ausbildung\n" +
  "\n" +
  "**Welche Berufsausbildung hast du abgeschlossen**\n" +
  "\n" +
  "- \n" +
  "\n" +
  "**Hast du einen Techniker / Meister oder sonstige Qualifikationen?**\n" +
  "\n" +
  "- \n" +
  "\n" +
  "## 📄 Berufserfahrung\n" +
  "\n" +
  "**Wo hast du bisher gearbeitet?**\n" +
  "\n" +
  "- \n" +
  "\n" +
  "**Welche Tätigkeiten hast du dort ausgeübt?**\n" +
  "\n" +
  "- \n" +
  "\n" +
  "**Hast du in deinem aktuellen Job Personalverantwortung?**\n" +
  "\n" +
  "- \n" +
  "\n" +
  "## 💸 Gehalt und Start\n" +
  "\n" +
  "**Wie lauten deine Gehaltsvorstellungen? Wie viel verdienst du aktuell?**\n" +
  "\n" +
  "- \n" +
  "\n" +
  "**Ab wann könntest du starten?**\n" +
  "\n" +
  "- \n" +
  "\n" +
  "## 🎉 Sonstige\n" +
  "\n" +
  "- Wann gut erreichbar\n" +
  "    - [ ]  Morgens\n" +
  "    - [ ]  Mittags\n" +
  "    - [ ]  Abends\n" +
  "\n" +
  "---\n" +
  "\n" +
  "# 🏈 Touchpoints\n" +
  "\n" +
  "-";
