import * as React from "react";
import { useState } from "react";
import { DefaultTheme } from "../DefaultTheme";
import { styled, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { MainListItems } from "./SideBar";
import { Container } from "@mui/material";
import { Logout } from "@mui/icons-material";
import useToken from "../../hooks/useToken";
import { useNavigate } from "react-router-dom";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

interface IDashboardContent {
  children: React.ReactNode;
}

export const DashboardContent = (props: IDashboardContent) => {
  const [open, setOpen] = useState(false);
  const { removeToken } = useToken();
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    removeToken();
    navigate("/login", { replace: true });
  };

  return (
    <ThemeProvider theme={DefaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          open={open}
          style={{ background: "#ffffff" }}
          elevation={1}
        >
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <img
              height={40}
              src={
                "https://matchandmake.de/wp-content/uploads/2022/04/matchandmake-logo-official.png"
              }
              alt={"logo"}
              loading={"lazy"}
              onClick={() => navigate("/")}
            />
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            />
            <IconButton color="warning" onClick={handleLogout}>
              <Badge color="secondary">
                <Logout />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar />
          <Divider />
          <List component="nav">{MainListItems()}</List>
          {
            // <List style={{ marginTop: `auto` }}>{secondaryListItems}</List>
          }
          <Divider style={{ marginTop: `auto` }} />
          <IconButton onClick={toggleDrawer}>
            {
              // TODO: Button hover on open looks odd
              open ? <ChevronLeftIcon /> : <ChevronRightIcon />
            }
          </IconButton>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: "white",
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container sx={{ mt: 4, mb: 4, ml: 1 }} maxWidth={false}>
            {props.children}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
