import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Divider, FormHelperText, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import { CustomButton } from "../general/CustomButton";
import { GeneralTextInput } from "../general/GeneralTextInput";
import { OnboardingPage } from "./OnboardingPage";
import { Formik } from "formik";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";

interface IChangePasswordScreenProps {
  readonly error: string;
  readonly onSetPasswordPress: (
    password: string,
    passwordConfirm: string,
    code: string
  ) => void;
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const ChangePasswordScreen: React.FunctionComponent<
  IChangePasswordScreenProps
> = (props) => {
  const query = useQuery();
  const changePasswordSchema = Yup.object().shape({
    password: Yup.string().required("Benötigt"),
    passwordConfirm: Yup.string().required("Benötigt"),
  });
  const [showPassword, onShowPassword] = useState<boolean>(false);
  return (
    <OnboardingPage>
      <Formik
        initialValues={{
          password: "",
          passwordConfirm: "",
        }}
        validateOnMount={true}
        validationSchema={changePasswordSchema}
        onSubmit={(values) => {
          const code = query.get("code");
          props.onSetPasswordPress(
            values.password,
            values.passwordConfirm,
            code as string
          );
        }}
      >
        {({
          values,
          touched,
          errors,
          handleSubmit,
          handleBlur,
          handleChange,
          isValid,
        }) => (
          <Box
            component={"form"}
            noValidate
            sx={{
              justifyContent: "center",
              display: "flex",
              flex: 1,
              flexDirection: "column",
              width: "100%",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={(theme) => ({
                [theme.breakpoints.up("sm")]: {
                  display: "flex",
                  flex: 0.5,
                  overflow: "hidden",
                  width: "90%",
                  height: 90,
                  pt: 5,
                },
              })}
            >
              <img
                height={60}
                src={
                  "https://matchandmake.de/wp-content/uploads/2022/04/matchandmake-logo-official.png"
                }
                alt={"logo"}
                loading={"lazy"}
              />
            </Box>
            <Box
              sx={(theme) => ({
                [theme.breakpoints.up("sm")]: {
                  width: "50%",
                },
                width: "85%",
              })}
            >
              <Typography
                component={"h1"}
                variant={"h3"}
                sx={{ color: "text.primary", fontFamily: "Poppins" }}
              >
                {"Willkommen bei matchandmake"}
              </Typography>
              <Typography
                component={"h1"}
                variant={"h5"}
                sx={{
                  color: "text.primary",
                  fontWeight: "400",
                  py: 2,
                  pb: 5,
                }}
              >
                {"Bitte geben Sie ihr neues Passwort ein."}
              </Typography>
              <GeneralTextInput
                isPassword
                label={"Neues Passwort"}
                placeholder={"Neues Passwort"}
                onBlur={handleBlur("password")}
                value={values.password}
                error={errors.password != undefined && touched.password}
                errorMessage={errors.password}
                icon={showPassword ? <VisibilityOff /> : <Visibility />}
                onIconClick={() => onShowPassword(!showPassword)}
                showPassword={showPassword}
                onTextChange={handleChange("password")}
              />

              <GeneralTextInput
                isPassword
                label={"Neues Passwort"}
                placeholder={"Neues Passwort bestätigen"}
                onBlur={handleBlur("passwordConfirm")}
                helperText={"Password"}
                value={values.passwordConfirm}
                error={
                  errors.passwordConfirm != undefined && touched.passwordConfirm
                }
                errorMessage={errors.passwordConfirm}
                icon={showPassword ? <VisibilityOff /> : <Visibility />}
                onIconClick={() => onShowPassword(!showPassword)}
                showPassword={showPassword}
                onTextChange={handleChange("passwordConfirm")}
              />
              {props.error !== "" && (
                <FormHelperText
                  sx={{
                    bottom: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      color: "error.main",
                      position: "absolute",
                    }}
                  >
                    {props.error}
                  </Typography>
                </FormHelperText>
              )}
              <Box
                sx={{
                  pt: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <CustomButton
                  disabled={!isValid}
                  text={"Passwort zurücksetzen"}
                  onClick={() => handleSubmit()}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flex: 0.5,
                justifySelf: "flex-end",
                alignSelf: "flex-end",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  alignSelf: "flex-end",
                  flexDirection: "column",
                  pb: 5,
                }}
              >
                <Divider
                  color={"#979797"}
                  sx={{ width: "75%", alignSelf: "flex-end", mr: 10 }}
                />
                <Box
                  sx={(theme) => ({
                    [theme.breakpoints.only("xs")]: {
                      flexDirection: "column",
                    },
                  })}
                  pt={2}
                  pr={5}
                  pl={5}
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <Link
                    target="_blank"
                    href={"https://matchandmake.de/impressum/"}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: "400",
                        color: "grey.A700",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        pr: 4,
                      }}
                    >
                      Nutzungsbedingungen
                    </Typography>
                  </Link>
                  <Link
                    target="_blank"
                    href={"https://matchandmake.de/datenschutz/"}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: "400",
                        color: "grey.A700",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        pr: 4,
                      }}
                    >
                      Datenschutzbestimmungen
                    </Typography>
                  </Link>
                  <Link
                    target="_blank"
                    href={"https://matchandmake.de/impressum/"}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: "400",
                        color: "grey.A700",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        pr: 4,
                      }}
                    >
                      Impressum
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Formik>
    </OnboardingPage>
  );
};
