import * as React from "react";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { forgotPassword } from "../infrastructure/api/auth";
import { ForgotPasswordScreen } from "../components/onboarding/ForgotPasswordScreen";
import useToken from "../hooks/useToken";

export default function ForgotPassword() {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { token } = useToken();

  const handlePasswordReset = async (email: string): Promise<void> => {
    try {
      await forgotPassword(email);
      navigate("/login", { replace: true });
    } catch (e) {
      setError("Etwas ist schiefgelaufen.");
    }
  };

  if (token) {
    return <Navigate to={"/"} replace={true} />;
  }

  return (
    <ForgotPasswordScreen
      error={error}
      onResetPress={(email) => handlePasswordReset(email)}
    />
  );
}
