import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface IQualifyModal {
  readonly open: boolean;
  readonly handleClose: () => void;
  readonly handleRelease: () => void;
  readonly handleDismiss: () => void;
}

export const QualifyModal = (props: IQualifyModal) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Ist der Bewerber Qualifiziert zur Freigabe?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <ul>
            <li>Hast du alle Daten geprüft?</li>
            <li>Erfüllt der Kandidat die Vorrausetzungen?</li>
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.handleDismiss}>
          Nicht Qualifiziert
        </Button>
        <Button variant="contained" onClick={props.handleRelease} autoFocus>
          Freigeben
        </Button>
      </DialogActions>
    </Dialog>
  );
};
