import * as React from "react";
import { useEffect, useState } from "react";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Divider,
  FormLabel,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import MDEditor from "@uiw/react-md-editor";
import { useNavigate, useParams } from "react-router-dom";
import {
  dismissLead,
  getLead,
  ICampaign,
  ICompany,
  IResponse,
  releaseLead,
  updateLeadStatus,
  uploadDocument,
} from "../infrastructure/api/leads";
import { defaultQualification } from "../infrastructure/defaultQualification";
import { updateApplicant } from "../infrastructure/api/applicants";
import useToken from "../hooks/useToken";
import { QualifyModal } from "../components/general/QualifyModal";
import { formatISO } from "date-fns";
import { QualificatioActionBar } from "../components/general/QualificationActionBar";
import { LeadExistsCallout } from "../components/general/LeadExistsCallout";
import InformationAccordion from "../components/qualification/InformationAccordion";
import { UploadFile } from "@mui/icons-material";

const QualificationSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().email().nullable(),
  number: Yup.string().nullable(),
  age: Yup.number().nullable(),
  education: Yup.string().nullable(),
  experience: Yup.string().nullable(),
  address: Yup.string().nullable(),
  salary: Yup.number().nullable(),
  startAt: Yup.date().nullable(),
  lastContact: Yup.date().nullable(),
  qualification: Yup.string().nullable(),
});

const initialValues = {
  name: "",
  email: "",
  number: "",
  age: 0,
  education: "",
  experience: "",
  address: "",
  salary: 0.0,
  startAt: "",
  lastContact: "",
  qualification: "",
};

export default function Qualification() {
  const { leadId } = useParams();
  const [applicant, setApplicant] = useState(initialValues);
  const [funnelResults, setFunnelResults] = useState<IResponse>();
  const [applicantId, setApplicantId] = useState("");
  const [company, setCompany] = useState<ICompany>();
  const [campaign, setCampaign] = useState<ICampaign>();
  const [leadStatus, setLeadStatus] = useState("");
  const [open, setOpen] = React.useState(false);
  const { removeToken } = useToken();
  const navigate = useNavigate();
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    text: "Erfolgreich geändert!",
  });

  useEffect(() => {
    const getData = async () => {
      if (leadId) {
        const response = await getLead(leadId);
        if (!response.applicant.data.attributes.qualification) {
          response.applicant.data.attributes.qualification =
            defaultQualification;
        }
        setApplicant(response.applicant.data.attributes);
        setApplicantId(response.applicant.data.id);
        setFunnelResults(response.response);
        setCampaign(response.campaign);
        setLeadStatus(response.status);
        setCompany(
          response.campaign.data.attributes.company
        );
      }
    };
    getData();
  }, [leadId]);

  const formik = useFormik({
    initialValues: applicant,
    validationSchema: QualificationSchema,
    onSubmit: async (values) => {
      const data = Object.assign({ id: applicantId }, values);
      data.lastContact = formatISO(new Date());
      // @ts-ignore
      await updateApplicant(data);
    },
    enableReinitialize: true,
  });

  const handleRequestError = (e: unknown) => {
    // @ts-ignore
    if (e.response.status === 401 || e.response.status === 403) {
      removeToken();
      navigate("/login", { replace: true });
    }
    // @ts-ignore
    if (e.response.status === 400 || e.response.status === 500) {
      setSnack({
        open: true,
        severity: "error",
        text: "Etwas ist schiefgelaufen, versuchen Sie es später erneut.",
      });
    }
  };

  const handleClickOpen = () => {
    try {
      formik.handleSubmit();
    } catch (e) {
      handleRequestError(e);
    }
    setOpen(true);
  };

  const handleReleaseLead = async () => {
    if (leadId) {
      try {
        await releaseLead(leadId);
        setSnack({
          open: true,
          severity: "success",
          text: "Bewerber wurde freigegeben",
        });
      } catch (e) {
        handleRequestError(e);
      }
    }
    setOpen(false);
  };

  const handleDismissLead = async () => {
    if (leadId) {
      try {
        await dismissLead(leadId);
        setSnack({
          open: true,
          severity: "success",
          text: "Bewerber als unqualifiziert markiert",
        });
      } catch (e) {
        handleRequestError(e);
      }
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveDraft = () => {
    if (leadId) {
      try {
        formik.handleSubmit();
        updateLeadStatus(leadId, "contacted");
        setSnack({
          open: true,
          severity: "success",
          text: "Bewerber wurde gespeichert",
        });
      } catch (e) {
        handleRequestError(e);
      }
    }
  };

  const handleSave = () => {
    if (leadId) {
      try {
        formik.handleSubmit();
        setSnack({
          open: true,
          severity: "success",
          text: "Bewerber wurde gespeichert",
        });
      } catch (e) {
        handleRequestError(e);
      }
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0 && leadId) {
      try {
        await uploadDocument(leadId, files[0]);
        setSnack({
          open: true,
          severity: "success",
          text: "Dokument erfolgreich hochgeladen",
        });
      } catch (e) {
        handleRequestError(e);
      }
    }
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
      }}
    >
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} xl={4} xs={4}>
          <Typography fontSize={"2rem"} fontWeight={700}>
            Qualifizieren
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={4}>
          <Typography fontSize={"2rem"} fontWeight={700}>
            {company?.data.attributes.name}
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={4}>
          <Typography fontSize={"2rem"} fontWeight={700}>
            {campaign?.data.attributes.name}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ p: 1 }} />
      <QualifyModal
        open={open}
        handleClose={handleClose}
        handleRelease={handleReleaseLead}
        handleDismiss={handleDismissLead}
      />
      <Grid container spacing={2}>
        <Grid item lg={8} md={8} xl={8} xs={8}>
          <Grid item lg={10} md={10} xl={10} xs={10}>
            <LeadExistsCallout leadId={leadId} />
          </Grid>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <Typography
                  fontSize={"1.5rem"}
                  fontWeight={700}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Kontakt Daten
                </Typography>
              </Grid>
              <Grid item xs={2} md={2}>
                <FormLabel>Name:</FormLabel>
              </Grid>
              <Grid
                item
                xs={10}
                md={8}
                style={{ paddingTop: 0, paddingBottom: 16 }}
              >
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label=""
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={0} md={2}></Grid>
              <Grid item xs={2} md={2}>
                <FormLabel>Email:</FormLabel>
              </Grid>
              <Grid
                item
                xs={10}
                md={8}
                style={{ paddingTop: 0, paddingBottom: 16 }}
              >
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label=""
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={0} md={2}></Grid>
              <Grid item xs={2} md={2}>
                <FormLabel>Nummer:</FormLabel>
              </Grid>
              <Grid
                item
                xs={10}
                md={8}
                style={{ paddingTop: 0, paddingBottom: 16 }}
              >
                <TextField
                  fullWidth
                  id="number"
                  name="number"
                  label=""
                  value={formik.values.number}
                  onChange={formik.handleChange}
                  error={formik.touched.number && Boolean(formik.errors.number)}
                  helperText={formik.touched.number && formik.errors.number}
                />
              </Grid>
              <Grid item xs={0} md={2}></Grid>
              <Grid item xs={2} md={2}>
                <FormLabel>Wohnort:</FormLabel>
              </Grid>
              <Grid
                item
                xs={10}
                md={8}
                style={{ paddingTop: 0, paddingBottom: 16 }}
              >
                <TextField
                  fullWidth
                  id="address"
                  name="address"
                  label=""
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={formik.touched.address && Boolean(formik.errors.address)}
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>
              <Grid item xs={0} md={2}></Grid>
              <Grid item xs={2} md={2}>
                <FormLabel>Alter:</FormLabel>
              </Grid>
              <Grid
                item
                xs={10}
                md={8}
                style={{ paddingTop: 0, paddingBottom: 16 }}
              >
                <TextField
                  fullWidth
                  id="age"
                  name="age"
                  label=""
                  value={formik.values.age}
                  onChange={formik.handleChange}
                  error={formik.touched.age && Boolean(formik.errors.age)}
                  helperText={formik.touched.age && formik.errors.age}
                />
              </Grid>
              <Grid item xs={0} md={2}></Grid>
            </Grid>
            <Divider sx={{ p: 1 }} />
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <Typography
                  fontSize={"1.5rem"}
                  fontWeight={700}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Beruf
                </Typography>
              </Grid>
              <Grid item xs={2} md={2}>
                <FormLabel>Ausbildung:</FormLabel>
              </Grid>
              <Grid
                item
                xs={10}
                md={8}
                style={{ paddingTop: 0, paddingBottom: 16 }}
              >
                <TextField
                  fullWidth
                  id="education"
                  name="education"
                  label=""
                  value={formik.values.education}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.education && Boolean(formik.errors.education)
                  }
                  helperText={formik.touched.education && formik.errors.education}
                />
              </Grid>
              <Grid item xs={0} md={2}></Grid>
              <Grid item xs={2} md={2}>
                <FormLabel>Erfahrung:</FormLabel>
              </Grid>
              <Grid
                item
                xs={10}
                md={8}
                style={{ paddingTop: 0, paddingBottom: 16 }}
              >
                <TextField
                  fullWidth
                  id="experience"
                  name="experience"
                  label=""
                  value={formik.values.experience}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.experience && Boolean(formik.errors.experience)
                  }
                  helperText={formik.touched.experience && formik.errors.experience}
                />
              </Grid>
              <Grid item xs={0} md={2}></Grid>
            </Grid>
            <Divider sx={{ p: 1 }} />
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <Typography
                  fontSize={"1.5rem"}
                  fontWeight={700}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Skript
                </Typography>
              </Grid>
              <Grid lg={1} md={1} xl={1} xs={1}>
              </Grid>
              <Grid lg={10} md={10} xl={10} xs={10}>
                <div data-color-mode="light">
                  <MDEditor
                    height={400}
                    value={formik.values.qualification}
                    onChange={(value) => {
                      if (value) {
                        formik.setFieldValue("qualification", value);
                      }
                    }}
                  />
                </div>
              </Grid>
              <Grid lg={1} md={1} xl={1} xs={1}>
              </Grid>
            </Grid>
            <Divider sx={{ p: 1 }} />
            <Grid container spacing={2} sx={{ pb: 10 }}>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <Typography
                  fontSize={"1.5rem"}
                  fontWeight={700}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Start und Lohn
                </Typography>
              </Grid>
              <Grid item xs={2} md={2}>
                <FormLabel>Frühster Start:</FormLabel>
              </Grid>
              <Grid
                item
                xs={10}
                md={8}
                style={{ paddingTop: 0, paddingBottom: 16 }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label=""
                    value={formik.values.startAt}
                    inputFormat={"dd/MM/yyyy"}
                    onChange={(value, second) => {
                      if (value) {
                        formik.setFieldValue("startAt", value);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={formik.touched.startAt && formik.errors.startAt}
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={0} md={2}></Grid>
              <Grid item xs={2} md={2}>
                <FormLabel>Gehalt:</FormLabel>
              </Grid>
              <Grid
                item
                xs={10}
                md={8}
                style={{ paddingTop: 0, paddingBottom: 16 }}
              >
                <TextField
                  fullWidth
                  id="salary"
                  type={"number"}
                  name="salary"
                  inputProps={{ step: 0.01 }}
                  label=""
                  value={formik.values.salary}
                  onChange={formik.handleChange}
                  error={formik.touched.salary && Boolean(formik.errors.salary)}
                  helperText={formik.touched.salary && formik.errors.salary}
                />
              </Grid>
              <Grid item xs={0} md={2}></Grid>
              <Grid item xs={2} md={2}>
                <FormLabel>Letzter Kontakt:</FormLabel>
              </Grid>
              <Grid
                item
                xs={10}
                md={8}
                style={{ paddingTop: 0, paddingBottom: 16 }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label=""
                    value={formik.values.lastContact}
                    inputFormat={"dd/MM/yyyy"}
                    onChange={(value, second) => {
                      if (value) {
                        formik.setFieldValue("lastContact", value);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={
                          formik.touched.lastContact && formik.errors.lastContact
                        }
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={0} md={2}></Grid>
            </Grid>
            <AppBar
              position="fixed"
              sx={{ top: "auto", bottom: 0, backgroundColor: "white" }}
            >
              <QualificatioActionBar
                draft={handleSaveDraft}
                disqualify={handleDismissLead}
                release={handleClickOpen}
                save={handleSave}
                status={leadStatus}
              />
            </AppBar>
          </form>
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={4}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Typography fontSize={"1.5rem"} fontWeight={700} sx={{ mt: 3, pb: 2 }}>
              Zusatzinformationen:
            </Typography>
            <InformationAccordion funnelResults={funnelResults} companyDetails={company} campaignDetails={campaign}/>
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Typography fontSize={"1.5rem"} fontWeight={700} sx={{ mt: 3, pb: 2 }}>
              Dokumente
            </Typography>
            <Button
              variant="contained"
              component="label"
              startIcon={<UploadFile />}
              sx={{ mt: 2 }}
            >
              Datei hochladen
              <input
                type="file"
                hidden
                onChange={handleFileUpload}
              />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ p: 1 }} />
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={() => setSnack({ ...snack, open: false })}
      >
        <Alert
          // @ts-ignore
          severity={snack.severity}
          onClose={() => setSnack({ ...snack, open: false })}
        >
          {snack.text}
        </Alert>
      </Snackbar>
    </Box>
  );
}
