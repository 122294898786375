import * as React from "react";
import useToken from "../hooks/useToken";
import { Navigate } from "react-router-dom";
import { DashboardContent } from "./layout/GeneralLayout";

export const ProtectedRoute = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { token } = useToken();

  if (!token) {
    return <Navigate to="/login" replace={true} />;
  }

  return <DashboardContent>{children}</DashboardContent>;
};
