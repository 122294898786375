import * as React from "react";
import MuiListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ModeIcon from '@mui/icons-material/Mode';
import ListIcon from "@mui/icons-material/List";
import VisibilityIcon from '@mui/icons-material/Visibility';
import SettingsIcon from "@mui/icons-material/Settings";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material";
import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar/AppBar";

interface ListItemButtonProps extends MuiAppBarProps {
  active?: boolean;
}

const ListItemButton = styled(MuiListItemButton, {
  shouldForwardProp: (prop) => prop !== "active",
})<ListItemButtonProps>(({ theme, active }) => ({
  backgroundColor: active ? "#ffe7ed" : "white",
  borderBottomRightRadius: theme.spacing(2),
  borderTopRightRadius: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

export const MainListItems = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <React.Fragment>
      <ListItemButton
        active={location.pathname === "/"}
        onClick={() => navigate("/")}
      >
        <ListItemIcon>
          <ListIcon
            color={location.pathname === "/" ? "secondary" : "inherit"}
          />
        </ListItemIcon>
        <ListItemText primary="Offene Bewerber" />
      </ListItemButton>
      <ListItemButton
        active={location.pathname === "/leads/draft"}
        onClick={() => navigate("/leads/draft")}
      >
        <ListItemIcon>
          <ModeIcon
            color={location.pathname === "/leads/draft" ? "secondary" : "inherit"}
          />
        </ListItemIcon>
        <ListItemText primary="In Bearbeitung" />
      </ListItemButton>
      {/*
        <ListItemButton
        active={location.pathname === "/leads/release"}
        onClick={() => navigate("/leads/release")}
      >
        <ListItemIcon>
          <VisibilityIcon
            color={location.pathname === "/leads/release" ? "secondary" : "inherit"}
          />
        </ListItemIcon>
        <ListItemText primary="Freigabe" />
      </ListItemButton> 
      */}
    </React.Fragment>
  );
};

export const secondaryListItems = (
  <React.Fragment>
    <ListItemButton>
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Einstellungen" />
    </ListItemButton>
  </React.Fragment>
);
