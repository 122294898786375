import { useState } from "react";

export default function useToken() {
  const getToken = (): string | undefined => {
    const tokenString = localStorage.getItem("token");
    if (tokenString) {
      const userToken = JSON.parse(tokenString);
      return userToken.token;
    }
    return undefined;
  };

  const getUser = (): IUser | undefined => {
    const userInfo = localStorage.getItem("user");
    if (userInfo) {
      const userInfoJson = JSON.parse(userInfo);
      return userInfoJson;
    }
    return undefined;
  };

  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState(getUser());

  const saveToken = (userToken: IToken) => {
    localStorage.setItem("token", JSON.stringify(userToken));
    setToken(userToken.token);
  };

  const removeToken = () => {
    localStorage.removeItem("token");
  };

  const saveUser = (user: IUser) => {
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  };

  const removeUser = () => {
    localStorage.removeItem("user");
  };

  return {
    setToken: saveToken,
    token,
    removeToken,
    setUser: saveUser,
    removeUser,
    user,
  };
}

interface IToken {
  token: string;
}

interface IUser {
  id: number;
  email: string;
  name: string;
}
