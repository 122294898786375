import CheckIcon from "@mui/icons-material/Check";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import {
  alpha,
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputBase,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { InputBaseComponentProps } from "@mui/material/InputBase/InputBase";
import React from "react";

interface IGeneralTextInputProps {
  readonly value: string;
  readonly label?: string;
  readonly id?: string;
  readonly onTextChange: (text: string) => void;
  readonly icon?: React.ReactElement;
  readonly onIconClick?: () => void;
  readonly type?: string;
  readonly multiline?: boolean;
  readonly minRows?: number;
  readonly isPassword?: boolean;
  readonly showPassword?: boolean;
  readonly disabled?: boolean;
  readonly required?: boolean;
  readonly error?: boolean;
  readonly onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  readonly onSubmit?: () => void;
  readonly autoFocus?: boolean;
  readonly placeholder?: string;
  readonly helperText?: string;
  readonly errorMessage?: string;
  readonly height?: number;
  readonly inputProps?: InputBaseComponentProps;
}

const Input = styled(InputBase)(({ theme, error }) => ({
  "& .MuiInputBase-input": {
    [theme.breakpoints.up("sm")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    borderRadius: 6,
    position: "relative",
    backgroundColor: "#FFFFFF",
    border: "1px solid #323232",
    fontFamily: "Poppins",
    height: 50,
    padding: "1px 17px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(
        error ? theme.palette.error.main : theme.palette.text.primary,
        0.15
      )} 0 0 0 0.2rem`,
      borderColor: theme.palette.text.primary,
    },
  },
}));

export const GeneralTextInput: React.FunctionComponent<
  IGeneralTextInputProps
> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const textFieldRef = React.useRef<any>(null);
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleWheel = (e: any) => e.preventDefault();
    if (textFieldRef != undefined && textFieldRef.current != undefined) {
      textFieldRef.current.addEventListener("wheel", handleWheel);
    }
  }, []);
  const onKeyDown = (
    event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      props.onSubmit?.();
    }
  };

  return (
    <FormControl
      sx={{
        flex: 1,
        display: "flex",
        pb: 2,
        "& .MuiInputLabel-root": {
          display: "flex",
        },
      }}
      variant={"standard"}
      error={props.error}
      required={props.required}
    >
      <Box display={"flex"} flexDirection={"column"}>
        <Typography mb={1} color={props.error ? "error" : ""}>
          {props.label}
          {props.required && " *"}
          {props.helperText != undefined && props.helperText !== "" && (
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              title={props.helperText}
              placement={"bottom"}
              arrow
            >
              <HelpOutlineRoundedIcon
                fontSize={"small"}
                sx={{ ml: 2, mb: -0.4, fontSize: 14, color: "#828686" }}
              />
            </Tooltip>
          )}
        </Typography>
        <Box display={"flex"} flexDirection={"row"}>
          <Input
            ref={textFieldRef}
            onWheel={(event) => event.currentTarget.blur()}
            error={props.error}
            type={
              props.isPassword && !props.showPassword
                ? "password"
                : props.type == undefined
                ? "text"
                : props.type
            }
            id={props.id}
            inputProps={props.inputProps}
            fullWidth
            disabled={props.disabled}
            multiline={props.multiline}
            minRows={props.minRows}
            onKeyDown={onKeyDown}
            autoFocus={props.autoFocus}
            value={props.value}
            placeholder={props.placeholder}
            required={props.required}
            onChange={(event) =>
              props.onTextChange(event.target.value as string)
            }
            onBlur={(event) => props.onBlur?.(event)}
          />

          <Box display={"flex"} sx={{ alignItems: "center" }}>
            <InputAdornment position={"end"}>
              <CheckIcon
                sx={{
                  color:
                    props.error != undefined &&
                    !props.error &&
                    props.value !== ""
                      ? "success.main"
                      : "transparent",
                }}
                fontSize={"small"}
              />
            </InputAdornment>
          </Box>
        </Box>
      </Box>
      {props.isPassword && (
        <Box position={"absolute"} right={30} top={"55%"} bottom={0}>
          <InputAdornment position="end">
            <IconButton onClick={() => props.onIconClick?.()}>
              {props.showPassword ? (
                <VisibilityOutlinedIcon
                  sx={{ color: "#E0E0E0" }}
                  fontSize={"inherit"}
                />
              ) : (
                <VisibilityOffOutlinedIcon
                  sx={{ color: "#E0E0E0" }}
                  fontSize={"inherit"}
                />
              )}
            </IconButton>
          </InputAdornment>
        </Box>
      )}
      {props.error &&
        props.errorMessage != undefined &&
        props.errorMessage.trim() != "" && (
          <FormHelperText
            sx={{
              bottom: 0,
            }}
          >
            <Typography
              sx={{ fontSize: 12, color: "error.main", position: "absolute" }}
            >
              {props.errorMessage}
            </Typography>
          </FormHelperText>
        )}
    </FormControl>
  );
};
