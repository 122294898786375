import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

interface ICalloutProps {
    readonly message: string
}

export const Callout = ({message }: ICalloutProps) => {
  return (
    <Card sx={{ bgcolor: 'rgba(255, 249, 235, 0.5)', mt: 1, mb: 1, display: "flex", border: 1, borderColor: "#FFECA1", boxShadow: 0 }}>
      <CardContent sx={{display: "flex", alignSelf: "center", gap: 2}}>
        <WarningIcon sx={{ color: "#D18E00"}} />
        <Typography variant="body2">
          {message}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default Callout;
