import { Button, CircularProgress } from "@mui/material";
import * as React from "react";
import { DefaultTheme as theme } from "../DefaultTheme";

interface CustomButtonProps {
  readonly disabled?: boolean;
  readonly type?: "primary" | "secondary";
  readonly loading?: boolean;
  readonly onClick: () => void;
  readonly text?: string;
  readonly icon?: React.ReactNode;
  readonly height?: number;
}

export const CustomButton: React.FC<CustomButtonProps> = ({
  disabled,
  onClick,
  text,
  icon,
  loading,
  type,
  height,
}) => {
  const isPrimary = type == undefined || type === "primary";

  return (
    <Button
      onClick={() => onClick()}
      disabled={disabled}
      variant={"outlined"}
      sx={{
        ":root:": {
          boxShadow: "none",
        },
        ":hover": {
          boxShadow:
            "0 2px 4px 0 rgba(168,40,29,0.64), 0 6px 12px 0 rgba(168,40,29,0.08), 0 12px 20px 0 rgba(168,40,29,0.06)",
        },
      }}
      style={{
        textTransform: "none",
        backgroundColor: isPrimary
          ? theme.palette.secondary.main
          : theme.palette.background.paper,
        borderColor: theme.palette.secondary.main,
        color: isPrimary ? "#FFFFFF" : theme.palette.secondary.main,
        opacity: disabled ? 0.5 : 1,
        height: height != undefined ? height : 60,
        minWidth: 115,
        padding: ".5rem 1rem",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: 6,
      }}
    >
      {!loading && icon && icon}
      {!loading && text && (
        <span
          style={{
            height: "100%s",
            paddingLeft: 5,
            fontSize: 20,
            fontFamily: "Poppins",
          }}
        >
          {text}
        </span>
      )}
      {loading && (
        <CircularProgress
          size={24}
          style={{
            color: isPrimary ? "#FFFFFF" : theme.palette.secondary.main,
          }}
        />
      )}
    </Button>
  );
};
