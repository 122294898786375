import React from "react";
import { Box, CssBaseline, Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import das_richtige_werkzeug from "../../assets/das_richtige_werkzeug.jpeg";

interface IOnboardingPageProps {
  readonly children: React.ReactNode;
}

export const OnboardingPage: React.FunctionComponent<IOnboardingPageProps> = (
  props
) => {
  return (
    <Grid container component={"main"} sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={0}
        md={4.5}
        xl={4.2}
        sx={{
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundPosition: "bottom",
          backgroundSize: 695,
          backgroundImage: `url(${das_richtige_werkzeug})`,
        }}
      ></Grid>
      <Grid item xs={12} sm={12} md={7.5} xl={7.8} component={Paper} square>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            justifyItems: "center",
            minHeight: "100vh",
          }}
        >
          {props.children}
        </Box>
      </Grid>
    </Grid>
  );
};
