import * as React from "react";
import { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { LeadList } from "../components/dashboard/LeadList";
import { getLeads, ILead } from "../infrastructure/api/leads";

export default function Dashboard() {
  const [leads, setLeads] = useState<ILead[]>([]);

  useEffect(() => {
    const test = async () => {
      const data = await getLeads({status: ["new"]});
      setLeads(data);
    };
    test();
  }, []);
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
      }}
    >
      <Typography fontSize={24} fontWeight={600} sx={{ pl: 3, pb: 2 }}>
        Dashboard
      </Typography>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <LeadList leads={leads} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
