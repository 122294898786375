import * as React from "react";
import { useState } from "react";
import { LoginScreen } from "../components/onboarding/LoginScreen";
import useToken from "../hooks/useToken";
import { Navigate, useNavigate } from "react-router-dom";
import { login } from "../infrastructure/api/auth";

export default function SignInSide() {
  const { token, setToken, setUser } = useToken();
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  const handleLogin = async (email: string, password: string) => {
    try {
      const response = await login(email, password);
      setToken({ token: response.data.jwt });
      setUser(response.data.user);
      navigate("/", { replace: true });
    } catch (e) {
      setError(true);
    }
  };

  if (token) {
    return <Navigate to={"/"} replace={true} />;
  }

  return (
    <LoginScreen
      error={error}
      onLoginPress={(email, password) => handleLogin(email, password)}
      onForgotPress={() => navigate("/forgot-password")}
    />
  );
}
