import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Box, Button } from "@mui/material";

interface ActionBarProps {
  draft(): void;
  disqualify(): void;
  release(): void;
  save(): void;
  status: string;
}

export const QualificatioActionBar = (props: ActionBarProps) => {
  return (
    <AppBar
      position="fixed"
      sx={{ top: "auto", bottom: 0, backgroundColor: "white" }}
    >
      <Toolbar style={{ justifyContent: "space-between" }}>
        <Box>
          <Button
            variant="text"
            color="secondary"
            sx={{ pr: 4, pl: 12 }}
            onClick={props.disqualify}
          >
            Nicht qualifiziert
          </Button>
        </Box>
        <Box>
            <Button
                variant="text"
                sx={{ mr: 4 }}
                color="secondary"
                onClick={props.save}
            >
                Speichern
            </Button>
            <Button
                variant="outlined"
                sx={{ mr: 4 }}
                color="secondary"
                onClick={props.draft}
            >
                Draft Speichern
            </Button>
          
            <Button
                variant="contained"
                color="secondary"
                onClick={props.release}
            >
                Kunden Freigabe
            </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
