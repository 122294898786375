import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { ILead } from "../../infrastructure/api/leads";
import { useNavigate } from "react-router-dom";

interface ILeadListProps {
  leads: ILead[];
}

export const LeadList = (props: ILeadListProps) => {
  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader title="Offene Bewerber" />
      <Box sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Nummer</TableCell>
              <TableCell>Beworben am</TableCell>
              <TableCell>Kampagne</TableCell>
              <TableCell>Kunde</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.leads.map((lead) => (
              <TableRow
                hover
                key={lead.id}
                onClick={() => navigate(`/qualification/${lead.id}`)}
              >
                <TableCell>{lead.response.firstName?.value}</TableCell>
                <TableCell>{lead.response.phone?.value}</TableCell>
                <TableCell>
                  {new Date(lead.completedAt).toLocaleString("de-De", {
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </TableCell>
                <TableCell>{lead.campaign.data.attributes.name}</TableCell>
                <TableCell>
                  {lead.campaign.data.attributes.company.data.attributes.name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
        }}
      >
        <Button
          color="secondary"
          endIcon={<ArrowRightIcon fontSize="small" />}
          size="small"
          variant="text"
        >
          Alle Anzeigen
        </Button>
      </Box>
    </Card>
  );
};
