import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Callout from './Callout';
import { isDuplicat } from '../../infrastructure/api/leads';

interface ILeadExistsProps {
    readonly leadId: string | undefined
}

export const LeadExistsCallout = ({leadId }: ILeadExistsProps) => {
  const [duplicate, setDuplicate] = useState(false)

  useEffect(() => {
    const getData = async () => {
      if (leadId) {
        const response = await isDuplicat(leadId)
        setDuplicate(response)
      }
      
    }
    getData();
  }, [leadId])

  if (duplicate) {
    return (
      <Grid item>
            <Callout message="Hat sich bereits beworben!" />
      </Grid>
    );
  }
  return <></>
}