import client from "./client";

export const login = async (email: string, password: string) => {
  return await client.post("auth/local", {
    identifier: email,
    password,
  });
};

export const forgotPassword = async (email: string) => {
  return await client.post("auth/forgot-password", {
    email,
  });
};

export const resetPassword = async (
  password: string,
  passwordConfirmation: string,
  code: string
) => {
  return await client.post("auth/reset-password", {
    password,
    passwordConfirmation,
    code,
  });
};
