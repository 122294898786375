import React from "react";
import { Navigation } from "./components/Navigation";
import { ThemeProvider } from "@mui/material";
import { DefaultTheme } from "./components/DefaultTheme";

function App() {
  return (
    <ThemeProvider theme={DefaultTheme}>
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
      </style>
      <Navigation />
    </ThemeProvider>
  );
}

export default App;
