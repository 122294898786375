import * as React from "react";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Divider, FormHelperText, Link, Typography } from "@mui/material";
import { CustomButton } from "../general/CustomButton";
import { GeneralTextInput } from "../general/GeneralTextInput";
import { OnboardingPage } from "./OnboardingPage";
import { Formik } from "formik";
import * as Yup from "yup";

interface IForgotPasswordScreenProps {
  readonly error: string;
  readonly onResetPress: (email: string) => void;
}

export const ForgotPasswordScreen: React.FunctionComponent<
  IForgotPasswordScreenProps
> = (props) => {
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().required("Bitte geben Sie eine Email an.").email(),
  });
  const [showPassword, onShowPassword] = useState<boolean>(false);
  return (
    <OnboardingPage>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={forgotPasswordSchema}
        validateOnMount={true}
        onSubmit={(values) => {
          props.onResetPress(values.email);
        }}
      >
        {({
          values,
          touched,
          errors,
          handleSubmit,
          handleBlur,
          handleChange,
          isValid,
        }) => (
          <Box
            component={"form"}
            noValidate
            sx={{
              justifyContent: "center",
              display: "flex",
              flex: 1,
              flexDirection: "column",
              width: "100%",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={(theme) => ({
                [theme.breakpoints.up("sm")]: {
                  display: "flex",
                  flex: 0.5,
                  overflow: "hidden",
                  width: "90%",
                  height: 90,
                  pt: 5,
                },
              })}
            >
              <img
                height={60}
                src={
                  "https://matchandmake.de/wp-content/uploads/2022/04/matchandmake-logo-official.png"
                }
                alt={"logo"}
                loading={"lazy"}
              />
            </Box>
            <Box
              sx={(theme) => ({
                [theme.breakpoints.up("sm")]: {
                  width: "50%",
                },
                width: "85%",
              })}
            >
              <Typography
                variant={"h3"}
                sx={{ color: "text.primary", fontFamily: "Poppins" }}
              >
                {"Willkommen bei matchandmake"}
              </Typography>
              <Typography
                variant={"h5"}
                sx={{
                  color: "text.primary",
                  fontWeight: "400",
                  py: 2,
                  pb: 5,
                }}
              >
                {
                  "Passwort vergessen? Kein Problem, gib einfach deine Email ein und wir schicken dir einen Link zum Passwort neu setzen"
                }
              </Typography>
              <GeneralTextInput
                label={"Email"}
                placeholder={"Email angeben..."}
                onBlur={handleBlur("email")}
                value={values.email}
                error={errors.email != undefined && touched.email}
                errorMessage={errors.email}
                icon={showPassword ? <VisibilityOff /> : <Visibility />}
                onIconClick={() => onShowPassword(!showPassword)}
                showPassword={showPassword}
                onTextChange={handleChange("email")}
              />
              {props.error !== "" && (
                <FormHelperText
                  sx={{
                    bottom: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      color: "error.main",
                      position: "absolute",
                    }}
                  >
                    {props.error}
                  </Typography>
                </FormHelperText>
              )}
              <Box
                sx={{
                  pt: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <CustomButton
                  disabled={!isValid}
                  text={"Email schicken"}
                  onClick={() => handleSubmit()}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flex: 0.5,
                justifySelf: "flex-end",
                alignSelf: "flex-end",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  alignSelf: "flex-end",
                  flexDirection: "column",
                  pb: 5,
                }}
              >
                <Divider
                  color={"#979797"}
                  sx={{ width: "75%", alignSelf: "flex-end", mr: 10 }}
                />
                <Box
                  sx={(theme) => ({
                    [theme.breakpoints.only("xs")]: {
                      flexDirection: "column",
                    },
                  })}
                  pt={2}
                  pr={5}
                  pl={5}
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <Link
                    target="_blank"
                    href={"https://matchandmake.de/impressum/"}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: "400",
                        color: "grey.A700",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        pr: 4,
                      }}
                    >
                      Nutzungsbedingungen
                    </Typography>
                  </Link>
                  <Link
                    target="_blank"
                    href={"https://matchandmake.de/datenschutz/"}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: "400",
                        color: "grey.A700",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        pr: 4,
                      }}
                    >
                      Datenschutzbestimmungen
                    </Typography>
                  </Link>
                  <Link
                    target="_blank"
                    href={"https://matchandmake.de/impressum/"}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: "400",
                        color: "grey.A700",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        pr: 4,
                      }}
                    >
                      Impressum
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Formik>
    </OnboardingPage>
  );
};
